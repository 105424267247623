import LocalizedStrings from 'react-localization';

const localizedStrings = new LocalizedStrings({
    en: {
        enterUserName: 'Enter your User Name',
        userNameError: 'Enter valid User Name',
        userNameRequired: 'User Name Required',
        enterPassword: 'Enter your password',
        passwordMin8len: 'Password should be of minimum 8 characters length',
        passRequired: 'Password Required',
        noAccount: 'Don\'t have a account yet?',
        yesAccount: 'Already have a account?',
        login: 'Login',
        logout: 'Logout',
        register: 'Register',
        home: 'Home',
        downloads: 'Downloads',
        donate: 'Donate',
        enterAmount: 'Enter your preferred amount',
        amountRequired: 'Minimum amount is 1$',
        donation: 'Donation',
        readMore: "Read More",
        submit: "Submit",
        select: "Select",
        forgotPassword: "Forgot your password?",
        clickHere: "Click Here",
        checkMail: "Kindly, Check your E-Mail",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        token: "Token",
        resetPassword: "Reset your password",
        passwordMatch: 'Passwords must match',
        invalidEmail: 'Invalid email',
        resetSuccess: 'Password reset successful, You will be redirected shortly',
        resetFailed: 'Password reset failed, You will be redirected shortly',
        errorMail: 'Unable to send E-mail to your registered email',
        registerSuccess: 'Account Registered successfully, Kindly verify your E-mail',
        emailUsed: 'E-mail already used.',
        contactSupport: 'An error occurred, kindly contact user support',
        emailError:  'Invalid E-mail, kindly enter correct email',
        enterEmail: 'Enter your email',
        emailRequired: 'Email is required',
        regLimitExceeded: 'Your registration limit exceeded',
        ipBanned: 'Your system is banned from this game',
        verify: 'Verify',
        verification: 'Verification',
        verificationSuccess: "Account Verified successfully, you'll be redirected shortly.",
        verifyAccount: "Kindly,check your mail and verify your account",
        loginSuccess: "Login Successfully",
        invalidLogin: "Invalid Login",
        invalidCredits: "Invalid credentials",
        resendVerification: "Resend Verification",
        verificationSent: "Verification Sent Successfully, kindly check your mail",
        verificationDefaultError: "An Unidentified error occurred kindly contact the team for further support",
        joinDiscord: "Join our discord channel for latest updates!",
        termsAndConditions: "Terms & Conditions",
        privacyPolicy: "Privacy Policy",
        disclaimer: "Disclaimer",
        refundPolicy: "Refund Policy",
        contactUs: "Contact Us",
        download: "Download"
    },
});
export default localizedStrings;
