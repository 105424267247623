import carouselImage1 from '../../../assets/img/cr-1.png'
import carouselImage2 from '../../../assets/img/cr-2.png'
import carouselImage3 from '../../../assets/img/cr-3.png'

const CarousalImages = [
    {
        photo: carouselImage1,
        label: "image1"
    },
    {
        photo: carouselImage2,
        label: "image2"
    },
    {
        photo: carouselImage3,
        label: "image3"
    }
];

export {
    CarousalImages
}
